import * as React from 'react'
import { January } from './January'
import { February } from './February'
import { March } from './March'
import { April } from './April'
import { May } from './May'
import { June } from './June'
import { July } from './July'

export function Year2021() {
  return (
    <>
      <July />
      <June />
      <May />
      {/* <April /> */}
      {/* <March /> */}
      {/* <February /> */}
      {/* <January /> */}
    </>
  )
}
